
.form-login {
  max-width: 400px;
  margin: auto;
}

.form-body {
  padding: 1em;
}

.form-body.shake {
  animation: shake 0.25s infinite;
}
@keyframes shake {
  0%  { transform: translate(2px, 1px)   }
  10% { transform: translate(-1px, -2px) }
  20% { transform: translate(-3px, 0px)  }
  30% { transform: translate(0px, 2px)   }
  40% { transform: translate(1px, -1px)  }
  50% { transform: translate(-1px, 2px)  }
  60% { transform: translate(-3px, 1px)  }
  70% { transform: translate(2px, 1px)   }
  80% { transform: translate(-1px, -1px) }
  90% { transform: translate(2px, 2px)   }
  100%{ transform: translate(1px, -2px)  }
}

.dialog-buttons {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.dialog-buttons-left {
  display: table-cell;
}

.dialog-buttons-right {
  text-align: right;
  display: table-cell;
}